export const neutral = {
  100: "#F0F4F8",
  200: "#D9E2EC",
  300: "#BCCCDC",
  400: "#9FB3C8",
  500: "#829AB1",
  600: "#627D98",
  700: "#486581",
  800: "#334E68",
  900: "#243B53",
  1000: "#102A43",
};

export const purple = {
  700: "#90279C",
  800: "#7C1A87",
};

export const green = {
  100: "#E3F9E5",
  200: "#C1F2C7",
  300: "#91E697",
  400: "#51CA58",
  500: "#31B237",
  600: "#18981D",
  700: "#0F8613",
  800: "#0E7817",
  900: "#07600E",
  1000: "#014807",
};

export const yellow = {
  100: "#FFFBEA",
  200: "#FFF3C4",
  300: "#FCE588",
  400: "#FADB5F",
  500: "#F7C948",
  600: "#F0B429",
  700: "#DE911D",
  800: "#CB6E17",
  900: "#B44D12",
  1000: "#8D2B0B",
};
