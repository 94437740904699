export const margins = {
  m1: "4px",
  m2: "8px",
  m3: "12px",
  m4: "16px",
  m5: "24px",
  m6: "32px",
  m7: "48px",
  m8: "64px",
  m9: "96px",
};
