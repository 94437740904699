import React from "react";
import styled from "styled-components";
import { margins, device } from "../utils";

const StyledSvg = styled.svg`
  grid-column: span 3;
  grid-row: 2;
  align-self: center;
  width: 100%;
  padding-bottom: ${margins.m3};
  @media screen and (min-width: ${device.small}) {
    grid-column: span 3;
    grid-row: span 2;
  }
  @media screen and (min-width: ${device.medium}) {
    grid-column: span 3;
    grid-row: span 2;
  }
  @media screen and (min-width: ${device.large}) {
    grid-column: span 4;
    grid-row: span 3;
  }
`;

const Illustration = () => (
  <StyledSvg
    viewBox="0 0 712 462"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0)">
      <path
        d="M695.047 366.416H505.738v1.216h189.309v-1.216zM711.653 390.138H556.22v1.217h155.433v-1.217z"
        fill="#3F3D56"
      />
      <path d="M595.405 150.054h-1.329v217.549h1.329V150.054z" fill="#3F3D56" />
      <path
        d="M594.996 348.959c14.308 0 25.906-24.237 25.906-54.136 0-29.899-11.598-54.137-25.906-54.137-14.307 0-25.905 24.238-25.905 54.137s11.598 54.136 25.905 54.136zM594.996 225.48c14.308 0 25.906-24.238 25.906-54.137 0-29.898-11.598-54.136-25.906-54.136-14.307 0-25.905 24.238-25.905 54.136 0 29.899 11.598 54.137 25.905 54.137z"
        fill="#3F3D56"
      />
      <path
        d="M595.633 127.114l-1.273-.349c.08-.246 7.89-24.755-4.934-40.533-7.243-8.91-19.672-13.43-36.941-13.43v-1.216c17.715 0 30.504 4.685 38.011 13.924 13.221 16.273 5.219 41.353 5.137 41.604z"
        fill="#3F3D56"
      />
      <path
        d="M552.485 87.401c9.171 0 16.606-6.808 16.606-15.206 0-8.399-7.435-15.207-16.606-15.207-9.171 0-16.606 6.808-16.606 15.207 0 8.398 7.435 15.206 16.606 15.206z"
        fill="#90279C"
      />
      <path
        d="M535.97 350.668h-17.271v-15.815h17.271v15.815zm-15.942-1.216h14.613V336.07h-14.613v13.382z"
        fill="#3F3D56"
      />
      <path
        d="M121.106 320.261c.022 40.902-26.524 55.194-59.277 55.208a107.03 107.03 0 01-6.777-.21c-29.562-1.903-52.538-16.906-52.558-54.946-.02-39.366 54.895-89.069 59.013-92.744.004 0 .004 0 .007-.003l.237-.211s59.334 52.006 59.355 92.906z"
        fill="#90279C"
      />
      <path
        d="M59.667 369.214l21.676-27.763-21.729 30.809-.056 3.187a99.353 99.353 0 01-4.506-.188l2.315-40.92-.019-.317.04-.06.22-3.867-21.816-30.866 21.88 27.97.055.82 1.75-30.917-18.68-31.898 18.906 26.464 1.804-64.099.007-.213v.21l-.279 50.549L79.8 258.067l-18.641 24.401-.477 27.683 17.338-26.574-17.405 30.644-.265 15.393 25.162-36.99-25.257 42.36-.587 34.23z"
        fill="#3F3D56"
      />
      <path
        d="M114.813 374.852H2.556v1.216h112.257v-1.216zM442.728 10.34H137.176v1.217h305.552v-1.216z"
        fill="#3F3D56"
      />
      <path
        d="M228.45 23.114c6.97 0 12.621-5.174 12.621-11.557S235.42 0 228.45 0c-6.97 0-12.621 5.174-12.621 11.557s5.651 11.557 12.621 11.557z"
        fill="#90279C"
      />
      <path
        d="M10.166 235.709a8.502 8.502 0 01-4.428-1.23c-1.31-.802-2.332-1.942-2.936-3.276a6.732 6.732 0 01-.453-4.218c.307-1.415 1.066-2.716 2.181-3.737s2.535-1.716 4.081-1.998a8.656 8.656 0 014.606.416c1.456.552 2.701 1.488 3.577 2.688a6.873 6.873 0 011.343 4.056c-.002 1.935-.843 3.79-2.337 5.159-1.494 1.368-3.52 2.138-5.634 2.14zm0-13.382a7.088 7.088 0 00-3.69 1.025c-1.092.668-1.944 1.618-2.446 2.73a5.606 5.606 0 00-.378 3.514 5.945 5.945 0 001.817 3.115 6.85 6.85 0 003.402 1.664 7.203 7.203 0 003.837-.346c1.214-.46 2.252-1.24 2.981-2.24.73-1 1.12-2.176 1.12-3.379-.002-1.613-.703-3.159-1.948-4.3-1.245-1.14-2.934-1.781-4.695-1.783zM123.409 208.302h-17.27v-15.815h17.27v15.815zm-15.942-1.216h14.613v-13.382h-14.613v13.382zM295.857 425.045c163.397 0 295.856-2.817 295.856-6.292 0-3.476-132.459-6.293-295.856-6.293C132.459 412.46 0 415.277 0 418.753c0 3.475 132.459 6.292 295.857 6.292zM295.857 443.924c113.121 0 204.823-1.95 204.823-4.357 0-2.406-91.702-4.356-204.823-4.356-113.121 0-204.824 1.95-204.824 4.356 0 2.407 91.703 4.357 204.824 4.357zM295.95 462c92.278 0 167.084-1.591 167.084-3.554 0-1.963-74.806-3.554-167.084-3.554s-167.084 1.591-167.084 3.554c0 1.963 74.806 3.554 167.084 3.554z"
        fill="#3F3D56"
      />
      <path
        d="M441.492 303.954l-.973 18.704 46.685 14.25s-2.432-23.602-.973-25.828l-44.739-7.126zM365.629 387.23l-4.376 15.586h20.424V387.23h-16.048z"
        fill="#FFB8B8"
      />
      <path
        d="M471.642 391.373l9.24 2.982 4.862-11.182-8.267-5.964-5.835 14.164z"
        fill="#A0616A"
      />
      <path
        d="M488.015 371.965s13.777-1.658 13.777 9.03c0 10.688 1.946 36.962-17.506 33.844 0 0-8.754.891-5.35-8.461 0 0 3.466-3.804 2.219-6.132-1.246-2.329-8.54-4.11-7.568-5.446.973-1.336 11.671-12.024 11.671-12.024l2.757-10.811z"
        fill="#2F2E41"
      />
      <path
        d="M488.176 334.682l-47.657-15.141-2.918 10.687s-71.485-37.852-75.862-12.023c0 0-3.891 20.93 1.945 48.54l.486 22.266s12.158 2.672 17.993.445l.487-24.047s4.863-15.586 2.431-27.61c0 0 36.472 26.274 53.007 29.391 0 0-57.87 40.97-51.062 53.884 6.809 12.914 15.076 15.141 25.774 12.914 7.373-1.534 40.38-25.909 60.189-40.915 6.79-5.094 12.182-11.575 15.742-18.921 3.561-7.347 5.192-15.356 4.762-23.384-.355-5.908-1.843-11.633-5.317-16.086z"
        fill="#2F2E41"
      />
      <path
        d="M364.657 397.027s-.487-5.344-3.405-3.118c-2.917 2.227-13.13 10.688-13.13 10.688s-36.958 3.715-14.588 12.176c10.216 1.407 20.658.173 30.15-3.562 0 0 24.855 5.843 24.342.173-.514-5.67-6.349-15.139-6.349-15.139s-6.808 4.571-14.589-1.664l-2.431.446z"
        fill="#2F2E41"
      />
      <path
        d="M460.943 252.743c9.938 0 17.993-7.377 17.993-16.477 0-9.1-8.055-16.477-17.993-16.477-9.937 0-17.993 7.377-17.993 16.477 0 9.1 8.056 16.477 17.993 16.477z"
        fill="#FFB8B8"
      />
      <path
        d="M475.532 237.156s2.918 13.36 8.267 16.477l-29.664 32.063-11.185-9.797 8.267-13.359s9.24-4.899 6.322-11.579l17.993-13.805zM486.231 254.969s21.397 4.008 23.828 16.922c2.432 12.915-2.431 36.517-2.431 36.517l-10.212 69.47s-.487 29.391-13.13 24.492c-12.644-4.898 3.404-27.164 3.404-27.164l4.376-43.642s1.946-36.961-1.945-41.414l-15.561-4.454s3.404-29.391 11.671-30.727z"
        fill="#FFB8B8"
      />
      <path
        d="M451.218 262.54s-14.589-.446-23.829 10.242-31.609 18.703-31.609 18.703l-55.924 20.04s-10.213-16.477-16.535-10.688c-6.321 5.789 11.672 20.485 19.939 18.704 8.267-1.782 58.355-15.587 58.355-15.587l39.877-16.476 9.726-24.938z"
        fill="#FFB8B8"
      />
      <path
        d="M483.046 252.572s-18.698 22.882-26.965 22.437c-8.267-.446-2.7-13.976-2.7-13.976l-5.835 1.336s-7.999 13.976-8.972 14.866c0 0-11.671 17.368 1.459 21.376l.486 9.351s32.582-1.336 46.684 5.344l2.918-23.156s-18.479-8.462-8.267-24.938c0 0 3.623-8.632 7.513-9.523l-6.321-3.117z"
        fill="#3F3D56"
      />
      <path
        opacity=".1"
        d="M487.933 374.983l-74.403 49.876s74.889-40.524 74.403-49.876z"
        fill="#000"
      />
      <path
        d="M479.666 237.824c.776 0 1.405-1.296 1.405-2.894 0-1.599-.629-2.895-1.405-2.895s-1.405 1.296-1.405 2.895c0 1.598.629 2.894 1.405 2.894z"
        fill="#FFB8B8"
      />
      <path
        d="M441.105 227.515s-5.043-31.191 30.971-21.567c0 0 25.478-4.745 34.476 24.394l9.134 30.55-4.181-1.989-1.848 3.895-6.672 1.673-2.979-5.165-1.255 6.357-54.811 5.979s24.317-13.752 24.206-37.632l-4.59 4.616-22.451-11.111z"
        fill="#2F2E41"
      />
      <path
        d="M317.717 99.385l-.138 71.095-.139 71.094-6.377-16.504v-.042l-8.272 158.271c8.133 20.99 12.477 32.204 12.523 32.246.047.043 4.668.297 10.26.551 16.913.761 37.894 2.412 53.837 4.231 3.697.424 6.839.635 7.07.466.231-.169.278-72.534.231-160.852l-.138-160.556h-68.857zM193.175 99.173v160.725c0 88.403.093 160.81.231 160.937.139.127 6.1-.423 13.31-1.143 7.209-.719 17.144-1.65 22.089-2.031 7.579-.592 30.315-1.904 32.903-1.947.786 0 .832-3.597.924-68.09l.139-68.09 5.499 14.219c.832 2.2 1.109 2.878 1.941 5.036l8.272-158.229c-1.756-4.528-.831-2.158-2.819-7.321-6.747-17.477-12.477-32.246-12.708-32.923l-.462-1.185h-69.319v.042z"
        fill="#B1060F"
      />
      <path
        d="M193.175 99.173l69.55 180.488v-.085l5.499 14.219c30.546 79.178 46.952 121.666 47.044 121.75.046.043 4.667.297 10.259.55 16.914.762 37.894 2.413 53.837 4.232 3.651.423 6.84.635 7.025.466L317.44 241.532v.042l-6.377-16.504c-6.239-16.123-10.398-26.914-35.491-91.831-6.747-17.477-12.478-32.246-12.709-32.923l-.462-1.185h-34.613l-34.613.042z"
        fill="#E50914"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <path fill="#fff" d="M0 0h711.653v462H0z" />
      </clipPath>
    </defs>
  </StyledSvg>
);

export default Illustration;
